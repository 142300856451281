import { useState } from 'react';

export default function MultiSelect(props) {
  const {
    question,
    onChange,
    onBlur,
    error,
    helperText,
    formikProps,
    inputStyles,
    disabled,
    ...rest
  } = props;

  const [selectedOption, setSelectedOption] = useState(formikProps.values[question.id]);
  const localServer = process.env.REACT_APP_SERVER_URL;
  console.log('formikProps before', formikProps);

  const handleOnChange = (value) => {
    console.log('formikProps', formikProps);
    setSelectedOption(value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <>
      <select
        className="text-small  custom-select emerald-font"
        value={selectedOption ? selectedOption : 'select'}
        onChange={(e) => handleOnChange(e.target.value)}
        disabled={disabled}>
        <option value="select" disabled>
          Please select
        </option>
        {question.options.map((option) => (
          <option
            className={selectedOption[question.id] === option.option_value ? 'selected-option' : ''}
            key={option.option_id}
            value={option.option_value}>
            {option.option_label}
          </option>
        ))}
      </select>
      {/* errors */}
      <label className="text-small orange-font"> {helperText} </label>
    </>
  );
}
