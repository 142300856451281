import './AppLayout.styles.css';
import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MoreIcon from '@mui/icons-material/MoreVert';
import logo from '../../images/Thermly-Orange.svg';
import { icons } from '../../utils/Icons';
import NotificationCenter from '../Notification/Notification.component';
import QuizIcon from '@mui/icons-material/Quiz';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../auth/AuthProvider';
import CustomButton from '../Common/Button/Button.component';
import { toggleDemoMode } from '../../redux/Actions/user.action';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import { Breadcrumbs, Typography } from '@mui/material';
import { getInitials, handleDownloadFile } from '../../utils/HelperFunctions';
import InstallerBreadcrumbsComponent from '../../pages/Installer/components/InstallerBreadcrumb.component';
import { Download } from '@mui/icons-material';

export default function AppHeader(props) {
  const { withLogin, menu, installer } = props;
  const { user, loading, logout } = useAuth();
  const dispatch = useDispatch();

  const demo = useSelector((state) => state.userReducer.demo);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const headerNavigate = () => {
    if (user) {
      installer
        ? navigate('/installer/dashboard')
        : navigate('/resident/resident-home', { state: { fromApp: true } });
    } else {
      window.location.href = 'https://www.thermly.co.uk/';
    }
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const triggerDemoMode = () => {
    dispatch(toggleDemoMode());
  };

  const handleLogout = async () => {
    logout(installer ? 'installer' : 'resident');
  };
  const MainMenu = (
    <>
      {menu?.length > 0 &&
        menu.map((item) => (
          <ul key={item.key}>
            <li className={'menu-list-item'}>
              <a href="#">
                <IconButton
                  size="large"
                  disableRipple={true}
                  style={{ color: 'var(--emerald-color)' }}
                  onClick={item.onclick}>
                  {icons[item.icon]}
                </IconButton>
              </a>
            </li>
          </ul>
        ))}
    </>
  );

  const menuId = 'primary-search-account-menu';
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem
        key={1}
        onClick={() => navigate(installer ? '/installer/profile' : '/resident/profile')}>
        <IconButton size="large" style={{ fontColor: 'var(emerald-color)' }}>
          {icons['profile']}
        </IconButton>
        <p>Profile</p>
      </MenuItem>

      <MenuItem key={2} onClick={() => handleLogout()}>
        <IconButton size="large">{icons['logout']}</IconButton>
        <p>Logout</p>
      </MenuItem>
      {/* {installer && (
        <MenuItem key={2} onClick={triggerDemoMode}>
          <IconButton size="large" style={{ color: 'black' }}>
            <QuizIcon />
          </IconButton>
          <p>Demo</p>
        </MenuItem>
      )} */}
      {/* {installer && (
        <MenuItem key={2}>
          <IconButton size="large" style={{ color: 'var(--emerald-color)' }}>
            <Download />
          </IconButton>
          <p onClick={() => handleDownloadFile('/Installer%20Handbook.pdf', 'Handbook')}>
            Handbook
          </p>
        </MenuItem>
      )} */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <div>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}>
        {menu?.length > 0 &&
          menu.map((item) => (
            <MenuItem key={item.key} onClick={item.onclick}>
              <IconButton size="large">{icons[item.icon]}</IconButton>
              <p>{item.name}</p>
            </MenuItem>
          ))}
        {menu?.length > 0 && <Divider variant="middle" />}
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton size="large">
            {loading ? <></> : user ? icons['profile'] : icons['login']}
          </IconButton>
          <p> Profile</p>
        </MenuItem>
      </Menu>
      {user && <NotificationCenter user={user} />}{' '}
    </div>
  );

  return (
    <>
      {installer && demo && (
        <div className={'header-banner flex-row align-item-center justify-space-around '}>
          <h1 className={'text-large white-font'}>
            You are currently in the demo version of Thermly. Click ‘Close Demo’ to return to your
            active installer home page.​
          </h1>
          <div className={'banner-button'}>
            <CustomButton
              small
              variant="outlined"
              cssType={'white'}
              label={'Close Demo'}
              onClick={triggerDemoMode}
            />
          </div>
        </div>
      )}
      <div className={!installer && withLogin ? 'app-header-border-container' : ''}>
        <div className={`app-header container-wide`}>
          <div className="app-header-container">
            <div className="app-header-left">
              <span className="logo">
                <img src={logo} alt="logo" width="120px" onClick={headerNavigate} />
              </span>
            </div>
            {withLogin && (
              <>
                <div className="app-header-right">
                  {installer && (
                    <div className="installer-breadcrumb d-tab-none">
                      <InstallerBreadcrumbsComponent />
                    </div>
                  )}
                  {renderMobileMenu}

                  <Box className={'app-header-right-cotainer'}>
                    {/* {MainMenu} */}

                    <div className={'menu-list-item'}>
                      <a href="#">
                        {user ? (
                          <button
                            className="profile-btn flex-center"
                            onClick={handleProfileMenuOpen}>
                            {installer
                              ? getInitials(user?.contact_name)
                              : getInitials(`${user?.first_name} ${user?.last_name}`)}
                          </button>
                        ) : (
                          <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            onClick={() =>
                              navigate(installer ? '/installer-auth' : '/resident-auth')
                            }>
                            {icons['login']}
                          </IconButton>
                        )}
                      </a>
                    </div>
                  </Box>
                  {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                      size="large"
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      style={{ color: 'black' }}>
                      <MoreIcon />
                    </IconButton>
                  </Box> */}
                  {renderProfileMenu}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
