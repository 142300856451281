// import required input components
import CustomTextField from './Inputs/TextField/TextField.component';
import CustomCheckbox from './Inputs/Checkbox/Checkbox.component';
import CustomRadioGroup from './Inputs/RadioGroup/RadioGroup.component';
import MonthDataInputComponent from './Inputs/MonthInput/MonthInput.component';
import CustomUpload from './Inputs/PhotoUpload/Photoupload.component';
import CheckboxWithDialog from './Inputs/Checkbox/CheckboxDialog/CheckboxDialog.component';
import CardWrapper from './Inputs/CardWrapper/CardWrapper';
import MultiInputCard from './Inputs/MultiInputCard/MultiInputCard.component';
import CustomInputRating from './Inputs/InputRating/InputRating.component';
import NativeRadioGroup from './Inputs/NativeRadioGroup/NativeRadioGroup.component';
import HelperThermly from '../../HelperThermly/HelperThermly.component';
import './Input.style.css';
import MultiSelect from './Inputs/MultiSelect/MultiSelect.component';
export default function Input({
  type,
  question,
  formikProps,
  autocomplete,
  labelStyles,
  inputStyles,
  onInputChange,
  autoSave,
  form_disabled,
  installer,
  detailedDataCapture,
  disabled
}) {
  // Renders the appropriate input component based on the type prop
  const renderDefault = () => renderTextField();

  const renderSingleCard = () => (
    <CardWrapper
      key={`C-${question?.id}`}
      questionsData={question}
      handleSubmit={(value) => onInputChange(question.id, value)}
    />
  );

  const renderCheckbox = () => (
    <CustomCheckbox
      question={question}
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      form_disabled={form_disabled}
      inputStyles={inputStyles}
      error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
      helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
    />
  );

  const renderNumericField = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <CustomTextField
        key={`CustomTextField-${question.id}`}
        id={question.id}
        variant="outlined"
        color="primary"
        type="number"
        form_disabled={form_disabled}
        inputStyles={inputStyles}
        autocomplete={autocomplete}
        name={question.id}
        placeholder={question.placeholder}
        formikProps={formikProps}
        value={formikProps.values[question.id]}
        onChange={(e) => {
          formikProps.handleChange(e);
        }}
        onBlur={(e) => {
          formikProps.handleBlur(e);
          onInputChange(question.id, e.target.value);
        }}
        onInputChange={onInputChange}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const renderPasswordField = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} 'flex-row'`} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <CustomTextField
        key={`CustomTextField-${question.id}`}
        id={question.id}
        variant="outlined"
        color="primary"
        inputStyles={inputStyles}
        form_disabled={form_disabled}
        type="password"
        autocomplete={autocomplete}
        name={question.id}
        placeholder={question.placeholder}
        formikProps={formikProps}
        value={formikProps.values[question.id]}
        onChange={(e) => {
          formikProps.handleChange(e);
        }}
        onBlur={(e) => {
          formikProps.handleBlur(e);
          onInputChange(question.id, e.target.value);
        }}
        onInputChange={onInputChange}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const renderTextField = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} 'flex-row'`} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <CustomTextField
        key={`CustomTextField-${question.id}`}
        id={question.id}
        variant="outlined"
        color="primary"
        inputStyles={inputStyles}
        form_disabled={form_disabled}
        type="text"
        disabled={disabled}
        autocomplete={autocomplete}
        name={question.id}
        placeholder={question.placeholder}
        formikProps={formikProps}
        value={formikProps.values[question.id]}
        onChange={(e) => {
          formikProps.handleChange(e);
        }}
        onBlur={(e) => {
          formikProps.handleBlur(e);
          onInputChange(question.id, e.target.value);
        }}
        onInputChange={onInputChange}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const renderRadioGroup = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      <label
        className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
        key={`questionDescription-${question.id}`}>
        {question.question_description}
      </label>
      <CustomRadioGroup
        key={`CustomRadio-${question.id}`}
        inputStyles={inputStyles}
        question={question}
        onChange={(e) => {
          formikProps.handleChange(e);

          onInputChange(question.id, e.target.value, formikProps); // Call onInputChange with the question ID and value
        }}
        formikProps={formikProps}
        form_disabled={form_disabled}
        onBlur={formikProps.handleBlur}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const renderUpload = (type) => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row InputLabel`} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <CustomUpload
        question={question}
        type={type}
        onChange={(value) => {
          // formikProps.handleChange(e);
          onInputChange(question.id, value); // Call onInputChange with the question ID and value
        }}
        installer={installer}
        form_disabled={form_disabled}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        onBlur={formikProps.handleBlur}
        autoSave={autoSave}
        formikProps={formikProps}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );

  const renderTandCdialog = () => (
    <div className="InputContainer">
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <CheckboxWithDialog
        question={question}
        onChange={formikProps.handleChange}
        onBlur={formikProps.handleBlur}
        form_disabled={form_disabled}
        inputStyles={inputStyles}
        labelStyles={labelStyles}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const renderMonthDataInput = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <MonthDataInputComponent
        onMonthDataChange={(value) => onInputChange(question.id, value)}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        formikProps={formikProps}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );

  const renderMultiInputCard = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
        {question.label}
      </label>
      {question.thermly && (
        <HelperThermly
          thermly_text={question.thermly_text}
          // thermly_image={question.thermly_image}
          inputStyles={inputStyles}
        />
      )}
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <MultiInputCard
        key={`MultiInputCard-${question.id}`}
        inputStyles={inputStyles}
        question={question}
        onChange={(value) => {
          onInputChange(question.id, value); // Call onInputChange with the question ID and value
        }}
        formikProps={formikProps}
        onBlur={formikProps.handleBlur}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const renderStarInput = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      <label
        className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
        key={`questionDescription-${question.id}`}>
        {question.question_description}
      </label>
      <CustomInputRating
        question={question}
        onChange={(e) => {
          formikProps.handleChange(e);
          onInputChange(question.id, e.target.value, formikProps);
        }}
        formikProps={formikProps}
        onBlur={formikProps.handleBlur}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
        inputStyles={inputStyles}
      />
    </div>
  );
  const renderTextBox = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} 'flex-row'`} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      {question.question_description && (
        <label
          className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
          key={`questionDescription-${question.id}`}>
          {question.question_description}
        </label>
      )}
      <CustomTextField
        key={`CustomTextField-${question.id}`}
        id={question.id}
        variant="outlined"
        color="primary"
        inputStyles={inputStyles}
        type="text"
        autocomplete={autocomplete}
        name={question.id}
        placeholder={question.placeholder}
        formikProps={formikProps}
        value={formikProps.values[question.id]}
        multiline={true} // This is a multiline text area
        rows={4}
        onChange={(e) => {
          formikProps.handleChange(e);
        }}
        onBlur={(e) => {
          formikProps.handleBlur(e);
          onInputChange(question.id, e.target.value);
        }}
        onInputChange={onInputChange}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );
  const nativeRadioGroup = () => (
    <div className="InputContainer">
      <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
        {question.label}
        <div className="inputHelperIcon flex-center">
          {question.thermly && (
            <HelperThermly
              thermly_text={question.thermly_text}
              // thermly_image={question.thermly_image}
              inputStyles={inputStyles}
            />
          )}
        </div>
      </label>
      <label
        className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
        key={`questionDescription-${question.id}`}>
        {question.question_description}
      </label>
      <NativeRadioGroup
        key={`CustomRadio-${question.id}`}
        inputStyles={inputStyles}
        question={question}
        onChange={(e) => {
          formikProps.handleChange(e);
          onInputChange(question.id, e.target.value, formikProps); // Call onInputChange with the question ID and value
        }}
        formikProps={formikProps}
        onBlur={formikProps.handleBlur}
        error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
        helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
      />
    </div>
  );

  const renderMultiSelect = () => (
    <div className="InputContainer">
      <div className="flex-card gap-half">
        <label className={`${labelStyles} flex-row `} key={`questionLabel-${question.id}`}>
          {question.label}
        </label>
        {console.log('s', question.value)}

        {question.thermly && (
          <HelperThermly
            thermly_text={question.thermly_text}
            // thermly_image={question.thermly_image}
            inputStyles={inputStyles}
          />
        )}
        {question.question_description && (
          <label
            className={`text-standard grey-font questionDescription ${detailedDataCapture && 'label-gap'}`}
            key={`questionDescription-${question.id}`}>
            {question.question_description}
          </label>
        )}

        <MultiSelect
          key={`MultiSelectCard-${question.id}`}
          inputStyles={inputStyles}
          question={question}
          onChange={(value) => {
            onInputChange(question.id, value); // Call onInputChange with the question ID and value
          }}
          formikProps={formikProps}
          onBlur={formikProps.handleBlur}
          error={formikProps.touched[question.id] && Boolean(formikProps.errors[question.id])}
          helperText={formikProps.touched[question.id] && formikProps.errors[question.id]}
        />
      </div>
    </div>
  );

  const renderInput = () => {
    switch (type) {
      // Render CheckboxGroup for multiChoice questions
      case 'checkbox':
        return renderCheckbox();
      // Render CustomTextField for numeric and text questions
      case 'numeric':
        return renderNumericField();
      case 'text':
        return renderTextField();
      case 'password':
        return renderPasswordField();
      case 'phone':
        return renderPhoneNumber();
      case 'radioGroup':
        return renderRadioGroup();
      case 'photo':
        return renderUpload('photo');
      case 'file':
        return renderUpload('file');
      case 'multiInput':
        return renderMonthDataInput();
      case 'singleCard':
        return renderSingleCard();
      case 'multiInputCard':
        return renderMultiInputCard();
      case 'starInput':
        return renderStarInput();
      case 'textbox':
        return renderTextBox();
      case 'nativeRadioGroup':
        return nativeRadioGroup();
      case 'tandcDialog':
        return renderTandCdialog();
      case 'multiSelect':
        return renderMultiSelect();
      // Return null for unknown question types
      default:
        return renderDefault();
    }
  };

  // Render the input component
  return <>{renderInput()}</>;
}
